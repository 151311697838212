/* eslint-disable react/jsx-fragments */
import { Button, Col, Flex, List, Row, Steps, Typography, notification } from "antd";
import moment, { Moment } from "moment";
import React, { FC, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "src/store";
import { resetPayIntent } from "src/store/stripe";
import { userSelector } from "src/store/user";
import { SvgIcon } from "../../../../../shared/icons";
import { Collapse, ConfirmButtons, Modal, TabsSwitch } from "../../../../ui";
import { CancelSubscriptionModal } from "../CancelSubscriptionModal";
import { LowerPriorityPlanModal } from "../LowerPriorityPlanModal";
import { PaymentIssueModal } from "../PaymentIssueModal";
import { PaymentMethodModal } from "../PaymentMethodModal";
import { SuccessfulActivationModal } from "../SuccessfulActivationModal";
import "./index.less";
import { enterprisePlan, plansData } from "./plansData";
import { useBreakpoints } from "../../../../../helpers/useBreakpoints";
import { CollapseProps } from "antd/lib";
import { patchUser } from "src/store/user/asyncRequests";
import PageLayout from "src/components/layout/PageLayout";
import { cancelSubscriptionV3 } from "src/store/stripe/asyncRequests";

interface PlansModalProps {
  onClose: () => void;
  onCloseAll?: () => void;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const Timer = ({
  midAfter,
  setMidAfter,
  isPriceDis,
  setIsPriceDis,
  promoter,
  setPromoter,
}: // align = right,
{
  // align: undefined | string;
  promoter: undefined | string;
  setPromoter: React.Dispatch<React.SetStateAction<any>>;
  isPriceDis: boolean;
  setIsPriceDis: React.Dispatch<React.SetStateAction<any>>;
  midAfter: moment.Moment | undefined;
  setMidAfter: React.Dispatch<React.SetStateAction<any>>;
}) => {
  // const [remainingTime, setRemainingTime] = useState(3 * 60 * 60)

  // useEffect(() => {
  //   const timer = setInterval(() => {
  //     setRemainingTime(prevTime => prevTime - 1)
  //   }, 1000)

  //   return () => clearInterval(timer)
  // }, [])

  // const formatTime = (timeInSeconds: number) => {
  //   const hours = Math.floor(timeInSeconds / 3600)
  //   const minutes = Math.floor((timeInSeconds % 3600) / 60)
  //   const seconds = Math.floor(timeInSeconds % 60)

  //   return `${hours.toString().padStart(2, "0")}:${minutes.toString().padStart(2, "0")}:${seconds
  //     .toString()
  //     .padStart(2, "0")}`
  // }

  // logic of the referral origin and timer
  const placeholderDate = moment().add(28.1213, "hours");
  const [endCouponDate, setEndCouponDate] = useState(placeholderDate);
  // const [toggleTimeFunction, setToggleTimeFunction] = useState(false);

  const usr = useSelector(userSelector);
  // const { subscription: subs } = useSelector(userSelector);
  console.log(usr, "usrusr");
  // const [endCouponDate, setEndCouponDate] = useState(null);
  const [remainingTime, setRemainingTime] = useState({ days: 0, hours: 0, minutes: 0, seconds: 0 });
  // const location = useLocation()

  const calculateRemainingTime = useCallback(() => {
    const currentTime = moment();
    const difference = endCouponDate.diff(currentTime);
    const remainingSeconds = Math.floor(difference / 1000);
    const days = Math.floor(remainingSeconds / (60 * 60 * 24));
    console.log(difference, "difference", days);
    const hours = Math.floor((remainingSeconds % (60 * 60 * 24)) / (60 * 60));
    const totalHoursLeft = Math.floor(remainingSeconds / (60 * 60));
    const minutes = Math.floor((remainingSeconds % (60 * 60)) / 60);
    const seconds = remainingSeconds % 60;
    return { days, hours, minutes, seconds, totalHoursLeft };
  }, [endCouponDate]);

  // const { user: subscription } = useSelector(userSelector);
  const [accCreated, setAccCreated] = useState<any>();
  useEffect(() => {
    const accCreatTime = usr?.subscription?.date_created;
    console.log(usr, "usr?.date_created");
    // const accCreatTime = "2024-07-27T12:31:10.670Z";
    if (usr?.new_payment_status !== "none" || !accCreatTime) {
      return;
    }

    setAccCreated(accCreatTime);
    // const date = moment(accCreatTime);
    // const oneDayLater = moment(date).add(1, 'days');
    let sixAMUTC: Moment;
    // Get current UTC time
    // console.log();
    const timeOpenedAcc = moment(accCreatTime);

    // Check if current time is before 5 AM UTC to give them a
    if (timeOpenedAcc.hours() < 6) {
      // Set time to 6 AM UTC for today
      sixAMUTC = timeOpenedAcc.clone().startOf("day").set({ hour: 6, minute: 0, second: 0 });
      // console.log(sixAMToday.format());
    } else {
      // Set time to 6 AM UTC for tomorrow
      sixAMUTC = timeOpenedAcc.clone().startOf("day").add(1, "day").set({ hour: 6, minute: 0, second: 0 });
      // console.log(sixAMTomorrow.format());
    }
    // const midnightAfter = moment(oneDayLater).utc().startOf('day');
    console.log(timeOpenedAcc, "accCreatTime", sixAMUTC.format(), "-", sixAMUTC);
    setMidAfter(sixAMUTC);
    setEndCouponDate(sixAMUTC);
  }, [usr]);

  // const [promoter, setPromoter] = useState<undefined|string>();
  useEffect(() => {
    let fpromRefCookie;
    if (document?.cookie?.split(";")?.some((cookie) => cookie?.trim()?.startsWith("_fprom_ref="))) {
      // First promoter check cookie

      fpromRefCookie =
        // @ts-ignore
        document?.cookie
          ?.split(";")
          ?.find((cookie) => cookie?.trim()?.startsWith("_fprom_ref="))
          ?.split("=")?.length > 1 &&
        document?.cookie
          ?.split(";")
          ?.find((cookie) => cookie?.trim()?.startsWith("_fprom_ref="))
          ?.split("=")[1]
          ?.trim();
    }
    if (fpromRefCookie === "dealai" || fpromRefCookie === "michel70") {
      setIsPriceDis(true);
      if (fpromRefCookie === "dealai") {
        setPromoter("Deal.Ai");
      } else {
        setPromoter("Cold.IQ");
      }
    }
  }, [document]);

  const now = moment();
  const [interval, setUpdateInterval] = useState<any>(null);
  // eslint-disable-next-line consistent-return
  useEffect(() => {
    console.log("accCreatTime2", !now?.isAfter(midAfter), usr?.new_payment_status === "none", accCreated, usr?.new_payment_status);
    if (endCouponDate && midAfter && !now?.isAfter(midAfter) && usr?.new_payment_status === "none" && accCreated) {
      const newInterval = setInterval(() => {
        setRemainingTime(calculateRemainingTime());
      }, 1000);
      setUpdateInterval(newInterval);
      return () => clearInterval(newInterval);
    } else {
      clearInterval(interval);
    }
  }, [endCouponDate, midAfter, usr, accCreated]);

  return (
    <Flex justify={"right"} className={"timer-wrapper"}>
      {/* <Typography.Paragraph className={"discount-timer"}>{formatTime(remainingTime)}</Typography.Paragraph> */}
      <Typography.Paragraph className={"discount-timer"}>
        {remainingTime.days > 1 ? `${remainingTime.days} days, ` : remainingTime.days ? `${remainingTime.hours} day, ` : ""}
        {remainingTime.hours > 1 ? `${remainingTime.hours} hours, ` : remainingTime.hours ? `${remainingTime.hours} hour, ` : "0 hours, "}
        {remainingTime.minutes > 1 ? ` ${remainingTime.minutes} minutes,` : remainingTime.minutes ? ` ${remainingTime.minutes} minute,` : " 0 minutes,"}
        {remainingTime.seconds > 1 ? ` ${remainingTime.seconds} seconds ` : remainingTime.seconds ? ` ${remainingTime.seconds} second ` : " 0 seconds "}
        left
      </Typography.Paragraph>
      <Row className={"gradient1"}></Row>
      <Row className={"gradient2"}></Row>
    </Flex>
  );
};

// export const countdownLogic =()=>{
//  const [isPriceDis, setIsPriceDis] = useState(false);
//   const [promoter, setPromoter] = useState();
//   useEffect(() => {
//     let fpromRefCookie;
//     if (document?.cookie?.split(';')?.some((cookie) => cookie?.trim()?.startsWith('_fprom_ref='))) {
//       // First promoter check cookie
//       fpromRefCookie =
//         document?.cookie
//           ?.split(';')
//           ?.find((cookie) => cookie?.trim()?.startsWith('_fprom_ref='))
//           ?.split('=')?.length > 1 &&
//         document?.cookie
//           ?.split(';')
//           ?.find((cookie) => cookie?.trim()?.startsWith('_fprom_ref='))
//           ?.split('=')[1]
//           ?.trim();
//     }
//     if (fpromRefCookie === 'dealai' || fpromRefCookie === 'michel70') {
//       setIsPriceDis(true);
//       if (fpromRefCookie === 'dealai') {
//         setPromoter('Deal.Ai');
//       } else {
//         setPromoter('Cold.IQ');
//       }
//     }
//   }, [document]);
//   return 0
// }

export const PlansModal: FC<PlansModalProps> = ({ onClose, onCloseAll, inDash }) => {
  const dispatch = useDispatch<AppDispatch>();
  const client = useSelector(userSelector);
  console.log(client, "client");
  const { isDesktop } = useBreakpoints();
  const [activeTab, setActiveTab] = useState("Meetz Plans");
  const [currentStep, setCurrentStep] = useState(0);
  const [cancelSubscription, setCancelSubscription] = useState<any>(false);
  const [requestedPlan, setRequestedPlan] = useState<any>();
  const [wantedPlan, setWantedPlan] = useState(null);
  const [isLowerPriorityPlanModalOpened, setIsLowerPriorityPlanModalOpened] = useState(false);
  const [isSuccessfulActivationModalOpened, setIsSuccessfulActivationModalOpened] = useState(false);
  const [isCancelSubscriptionModalOpened, setIsCancelSubscriptionModalOpened] = useState(false);
  const [isPaymentIssueModalOpened, setIsPaymentIssueModalOpened] = useState(false);
  const [isPaymentMethodModalOpened, setIsPaymentMethodModalOpened] = useState(false);
  // const [keepOpen, setKeepOpen] = useState(false);

  const handleOnCloseAll = () => {
    if (onCloseAll) {
      onCloseAll();
    } else {
      onClose();
    }
  };

  const handleActivePlanBtnClick = (plan: any) => {
    console.log(client?.new_payment_status, "client?.new_payment_status");
    if (!client?.marked_as_initiate_checkout) {
      dispatch(patchUser({ marked_as_initiate_checkout: true }));
    }
    if (!client?.new_payment_status || client?.new_payment_status === "none" || client?.new_payment_status === "churned") {
      // first version only handle this case of purchasing upsell here. then start working on the rest of it
      setRequestedPlan(plan.planBeName);
      setIsPaymentMethodModalOpened(true);
      return;
    } else {
      return;
    }

    if (plan.planBeName !== client?.new_subscription_plans) {
      setWantedPlan(plan);

      if (
        (client?.new_subscription_plans === "scheduler" && plan.planBeName === "starter") ||
        (client?.new_subscription_plans === "starter" && plan.planBeName === "scheduler")
      ) {
        // same price just changing the plan
      }
      // check here if lower or higher plan and adjust accrodingly. lower and cancellation should remind that they are getting 20% off or 20% more credits
      // if (plan.priority < currentPlan?.priority) {
      //   openLowerPriorityPlanModal()
      // } else {
      //   handleChangePlan(plan)
      // }
    }
  };

  const handleChangePlan = (plan: any) => {
    setRequestedPlan(plan);
    // check if changed correctly or not in upgrade downgrade later
    openSuccessfulActivationModal();
  };

  const handleContactSalesBtnClick = () => {
    // window.location.href = "/book-meeting"

    window.open("/cal/enterprise", "_blank", "noopener,noreferrer");
  };

  //https://app.meetz.ai/cal/enterprise

  const handleCheckPaymentDetails = () => {
    closePaymentIssueModal();
    openPaymentMethodModal();
  };

  const handleContactSupport = () => {
    window.location.href = "/";
  };

  const openLowerPriorityPlanModal = () => {
    setIsLowerPriorityPlanModalOpened(true);
  };

  const closeLowerPriorityPlanModal = () => {
    setIsLowerPriorityPlanModalOpened(false);
  };

  const openSuccessfulActivationModal = () => {
    setIsSuccessfulActivationModalOpened(true);
  };

  const closeSuccessfulActivationModal = () => {
    setIsSuccessfulActivationModalOpened(false);
  };

  const openCancelSubscriptionModal = () => {
    setIsCancelSubscriptionModalOpened(true);
  };

  const closeCancelSubscriptionModal = () => {
    setIsCancelSubscriptionModalOpened(false);
  };

  const openPaymentIssueModal = () => {
    setIsPaymentIssueModalOpened(true);
  };

  const closePaymentIssueModal = () => {
    setIsPaymentIssueModalOpened(false);
  };

  const openPaymentMethodModal = () => {
    setIsPaymentMethodModalOpened(true);
  };

  const closePaymentMethodModal = () => {
    dispatch(resetPayIntent());
    setIsPaymentMethodModalOpened(false);
  };

  const [location, setLocation] = useState({ country: "", postal: "" });

  useEffect(() => {
    // Fetch geolocation data from the API
    fetch("https://ipapi.co/json/")
      .then((response) => response.json())
      .then((data) => {
        setLocation({
          country: data?.country_name,
          postal: data?.postal,
        });
      })
      .catch((error) => console.log("Error fetching geolocation data:", error));
  }, []);

  const items: CollapseProps["items"] = [
    {
      key: "1",
      label: "How easy is it to set up Meetz?",
      children: (
        <>
          <b> Very easy!</b> A one time quick set up and then the outreach is automated. <br />
          <br />
          It's 2 steps to start an outreach campaign:
          <br />
          <b> 1.</b> Choose which prospects you want Meetz to reach out to from our 250 million prospect database. <br />
          <b> 2.</b> Write what your business offers, and then Meetz will automatically draft the entire sequence and constantly A/B test every step by checking
          what unique copy is getting you the most results.
        </>
      ),
    },
    {
      key: "2",
      label: "How are the Meetz emails personalized?",
      children: (
        <>
          <b>
            {" "}
            All of Meetz personalization is done automatically with no need for any work from your end! We use the below to try and tailor the messaging of your
            value proposition.
          </b>{" "}
          <br />
          <br />
          <b>1.</b> We check if your prospects engaged with any relevant LinkedIn posts <br />
          <br />
          <b>2.</b> If they're not on LinkedIn, we'll use their bio, company info, or job title
          <br />
          <br />
          <b>3.</b> All personalized emails will be unique for every prospect
        </>
      ),
    },
    // {
    //   key: "3",
    //   label: "Difference between Ai personalized emails and templated emails?",
    //   children: (
    //     <>
    //       <b>All campaigns are automatically drafted by Ai, so what's the difference between the 2?</b>
    //       <br />
    //       <br />
    //       <b>Ai Personalized Emails:</b> Emails that are uniquely personalized one by one based on your prospects in order to introduce them to your services
    //       <br />
    //       <b>Templated emails:</b> Ai will A/B test every email to ensure your messaging is the most optimized and to maximize how many clients you can get from
    //       your campaigns
    //     </>
    //   ),
    // },
    {
      key: "4",
      label: "Can I cancel anytime?",
      children: (
        <>
          Yes, you cancel Meetz during the trial or after the subscription starts. Meetz is month to month with <b>no time period commitment.</b> <br />
          You can cancel via the platform or simply contact support through chat or Support@Meetz.ai <br />
          <br />
          While you're with us we're here to help you easily generate as many leads as possible! 🚀
          {/* Yes! You won't want to though once you start getting a steady influx of responses, activate the trial to get started!
          <br />
          <br />
          We have a mutual interest in getting you as many clients as possible!  */}
        </>
      ),
    },
  ];

  const stepsItems = [
    {
      title: "Today",
      description: <Typography.Paragraph className={"steps-description"}>Start 14 Day Free Trial & Generate Traction</Typography.Paragraph>,
    },
    {
      title: "Day 12",
      description: (
        <Typography.Paragraph className={"steps-description"}>An email reminder will notify you that your trial is almost over</Typography.Paragraph>
      ),
    },
    {
      title: "Day 14",
      description: (
        <Typography.Paragraph className={"steps-description"}>
          After your trial ends, your subscription begins.{" "}
          <Typography.Text className={"steps-link"} onClick={openCancelSubscriptionModal}>
            Cancel anytime.
          </Typography.Text>
        </Typography.Paragraph>
      ),
    },
  ];
  const [midAfter, setMidAfter] = useState<Moment>();
  const [isPriceDis, setIsPriceDis] = useState(false);
  const [promoter, setPromoter] = useState();
  const now = moment();
  const { Text } = Typography;

  const handleSuccessfulPayment = () => {
    setIsPaymentMethodModalOpened(false);
    setIsSuccessfulActivationModalOpened(true);
  };

  const cancelSubscriptionNow = () => {
    dispatch(cancelSubscriptionV3());

    setTimeout(() => {
      window.location.reload();
    }, 2000);
  };
  const handleCancelSubscription = () => {
    notification.info({
      message: "Subscription has been cancelled!",
      description: false,
      duration: 5,
      icon: <SvgIcon type={"infoFilled"} />,
      closeIcon: false,
      placement: "bottomRight",
    });
    // setCurrentPlan(null)
    closeCancelSubscriptionModal();
  };

  return (
    <React.Fragment>
      <Modal open={cancelSubscription} title={"Cancel subscription"} centered={isDesktop} onCancel={() => setCancelSubscription(false)}>
        <span style={{ color: "red" }}>Warning cancelling the subscription will do the following:</span>
        <br />
        1. Stop all your campaigns <br />
        2. Erase all optimizations made so far
        <br />
        3. Erase your accounts setup
        <br />
        <br />
        We care about your success using Meetz Ai.
        <br /> Need advice on how to boost your lead generation with Meetz? Click the support widget at the bottom right of the screen, and we'll set up a call
        or can remotely check how to best optimize your lead generation. <br />
        <b>Our number 1 priority is helping our clients succeed</b>, we love success stories that come from using Meetz.
        <br />
        <br />
        <ConfirmButtons
          rightButtonTitle={"Cancel Subscription Anyways"}
          // onCancel={() => setCancelSubscription(false)}
          handleSubmit={cancelSubscriptionNow}
          cancellation
        />{" "}
        <Text className={"content-footnote"}>*The above can't be reversed</Text>
      </Modal>
      <Modal
        rootClassName={"plans-modal"}
        open={!isSuccessfulActivationModalOpened && !isPaymentMethodModalOpened && !isCancelSubscriptionModalOpened && !cancelSubscription}
        centered={isDesktop}
        closeIcon={false}
        onCancel={onClose}
        // closable={client?.new_payment_status !== "none"}
      >
        {isDesktop ? (
          <>
            <Flex
              justify={now?.isAfter(midAfter) ? "center" : "space-between"}
              align={"center"}
              className={"discount-wrapper"}
              style={{ display: isPriceDis || (midAfter && !now?.isAfter(midAfter)) ? "flex" : "none" }}
            >
              <Typography.Paragraph className={"discount-text"}>
                {isPriceDis
                  ? `Exclusive ${promoter} Package • 20% Discount On Your Meetz Plan, For The First 12 Months!`
                  : `New User Discount 🚀 20% Extra Credits On Your Meetz Plan, For The First 12 Months!`}
              </Typography.Paragraph>
              <div className={"package-countdown"} style={{ display: !now?.isAfter(midAfter) ? "inline" : "none" }}>
                <Timer
                  midAfter={midAfter}
                  setMidAfter={setMidAfter}
                  isPriceDis={isPriceDis}
                  setIsPriceDis={setIsPriceDis}
                  promoter={promoter}
                  setPromoter={setPromoter}
                />
              </div>
            </Flex>
          </>
        ) : (
          // <Flex vertical={true} align={"center"} className={"discount-wrapper"}>
          <div className={"deal-package-wrapper-mobile"} style={{ display: isPriceDis || (midAfter && !now?.isAfter(midAfter)) ? "block" : "none" }}>
            <Typography.Paragraph className={"deal-package-title"}>
              {isPriceDis
                ? `Exclusive ${promoter} Package • 20% Discount On Your Meetz Plan, For The First 12 Months!`
                : !now?.isAfter(midAfter)
                ? `New User Discount 🚀 20% Extra Credits On Your Meetz Plan, For The First 12 Months!`
                : ""}
              <div className={"package-countdown"} style={{ display: !now?.isAfter(midAfter) ? "block" : "none" }}>
                <Timer
                  midAfter={midAfter}
                  setMidAfter={setMidAfter}
                  isPriceDis={isPriceDis}
                  setIsPriceDis={setIsPriceDis}
                  promoter={promoter}
                  setPromoter={setPromoter}
                  // align="flex-end"
                />
              </div>
            </Typography.Paragraph>
            <Row className={"gradient1"}></Row>
            <Row className={"gradient2"}></Row>
          </div>
          // </Flex>
        )}
        <Flex vertical={!isDesktop} className={"header"}>
          <Flex vertical={!isDesktop} justify={"space-between"} align={"center"} style={{ width: "100%", position: "relative" }}>
            <div>
              <Flex align="center">
                {" "}
                {client?.new_payment_status === "none" && (
                  <>
                    {" "}
                    <SvgIcon type={"arrowBackAccent"} width={20} height={20} style={{ cursor: "pointer", marginRight: "10px" }} onClick={onClose} />
                    <div> Back to intro </div>
                  </>
                )}
              </Flex>
              <Typography.Title level={2} className={"main-title"}>
                Plans And Pricing
              </Typography.Title>
            </div>
            <div
              style={{
                position: isDesktop ? "absolute" : "relative",
                margin: "30px auto 10px auto",
                left: isDesktop ? "50%" : "auto",
                transform: isDesktop ? "translateX(-50%)" : "none",
              }}
            >
              <TabsSwitch
                firstTab={"Meetz Plans"}
                secondTab={"Ai Email Scheduler Plan"}
                activeTab={activeTab}
                setActiveTab={setActiveTab}
                isMediumSize={true}
                className="custom-tabs"
              />
            </div>
            {client?.new_payment_status !== "none" && <SvgIcon type={"close"} width={20} height={20} style={{ cursor: "pointer" }} onClick={onClose} />}
          </Flex>
        </Flex>
        <br />
        {/* <Row className={"steps-wrapper"}>
          <Steps
            current={currentStep}
            size={"small"}
            direction={isDesktop ? "horizontal" : "vertical"}
            labelPlacement={"vertical"}
            items={stepsItems}
            progressDot={() => <Row className={"steps-dot"}></Row>}
          />
        </Row> for trial - testing from business shows this increases cancellation but is worth to A/B test in the future if this increases sign ups */}
        <Flex vertical={!isDesktop} wrap={isDesktop ? "wrap" : "unset"} gap={24} className={"plans-wrapper"}>
          {plansData.map((plan, i) => {
            // console.log(plan.name , client?.new_subscription_plans,'sub');
            if ((activeTab === "Meetz Plans" && plan?.id === "4") || (activeTab !== "Meetz Plans" && plan?.id !== "4")) {
              return "";
            }
            return (
              <Flex
                key={i}
                vertical={true}
                className={"plan"}
                style={{
                  background: plan.name === "Growth" ? `linear-gradient(135deg, #14476d, #2ab4e8)` : "#FFF",
                  // background: plan.name === "Growth" ? "linear-gradient(314deg, rgb(36 235 211 / 16%) -1.63%, rgb(0 173 238 / 16%) 71.91%), #FFF" : "#FFF",
                  margin: "0 auto",
                  color: plan.name === "Growth" ? "white" : undefined,

                  // boxShadow: `0px 4px 8px rgba(8, 145, 196, 0.4)`,
                }}
              >
                <Col
                  className={"plan-header"}
                  style={{
                    paddingRight: plan.name === "Starter" ? 16 : plan.name === "Growth" ? 8 : 24,
                    paddingTop: plan.name === "Growth" ? "12px" : "39px",
                  }}
                >
                  {plan.name === "Growth" && <div className={"plan-ispop"}>Popular</div>}
                  <Flex gap={8} className={"plan-title-wrapper"}>
                    <SvgIcon type={plan.iconType} width={35} height={35} />
                    {/* <Flex justify="space-between"> */}
                    <Typography.Title level={3} className={"plan-title"} style={{ color: plan.name === "Growth" ? "white" : undefined }}>
                      {plan.name}
                    </Typography.Title>

                    {/* </Flex> */}
                  </Flex>
                  <Typography.Paragraph className={"plan-description"} style={{ color: plan.name === "Growth" ? "white" : undefined }}>
                    {plan.description}
                  </Typography.Paragraph>
                </Col>
                <Col className={"plan-main"}>
                  <Col className={"plan-price-block"}>
                    <Flex gap={16} justify={"center"} align={"center"}>
                      {isPriceDis && (
                        <Typography.Paragraph className={"previous-price"} style={{ color: plan.name === "Growth" ? "white" : undefined }}>
                          ${plan.previousPrice}
                        </Typography.Paragraph>
                      )}
                      <Typography.Paragraph className={"current-price"} style={{ color: plan.name === "Growth" ? "white" : undefined }}>
                        ${!isPriceDis ? plan.previousPrice : plan.currentPrice}
                        <span style={{ fontSize: "14px", fontWeight: 400 }}>/monthly</span>
                      </Typography.Paragraph>
                    </Flex>
                    {isPriceDis ? (
                      <Typography.Paragraph className={"plan-discount"}>{plan.discountPercent}% Off</Typography.Paragraph>
                    ) : midAfter && !now?.isAfter(midAfter) && plan?.id !== "4" ? (
                      <Typography.Paragraph className={"plan-discount"}>20% Extra Credits</Typography.Paragraph>
                    ) : (
                      ""
                    )}
                  </Col>
                  <div style={{ alignSelf: "flex-end", fontWeight: 600, fontSize: "16px" }}> What's Included?</div>
                  <Typography.Paragraph style={{ fontWeight: 600, opacity: plan.name === "Growth" ? 1 : 0, marginTop: "10px", color: "white" }}>
                    ⭐ Ai Email Scheduler Plan Included ⭐
                  </Typography.Paragraph>
                  {
                    <div
                      style={{
                        alignSelf: "flex-end",
                        fontWeight: 600,
                        // opacity: plan.name === "Growth" || plan.name === "Ultimate" ? 1 : 0,
                        marginTop: "10px",
                      }}
                    >
                      {" "}
                      {plan.name === "Growth" || plan.name === "Ultimate" ? (
                        <>Features In {plan.name === "Growth" ? "Starter" : "Growth"} Plan Plus:</>
                      ) : (
                        "Get familiar with Meetz:"
                      )}
                    </div>
                  }
                  <List
                    dataSource={(midAfter && !now?.isAfter(midAfter)) || isPriceDis ? plan.extraAdvantages : plan.advantages}
                    renderItem={(item, itIndex) => (
                      <List.Item style={{ opacity: plan.name !== "Growth" && !itIndex ? 1 : 1 }}>
                        <SvgIcon type={plan.name === "Growth" ? "checkWhite" : "checked2"} width={23} height={23} className={"advantage-icon"} />
                        {item}
                      </List.Item>
                    )}
                    style={{ marginTop: 10 }}
                  />
                </Col>
                <ConfirmButtons
                  className={"confirm-buttons"}
                  // leftButtonTitle={"14 Day Free Trial"}
                  rightButtonTitle={
                    client?.new_payment_status === "none"
                      ? "Start trial"
                      : plan.planBeName === client?.new_subscription_plans
                      ? client?.new_payment_status === "trial"
                        ? "Trial Plan"
                        : "Current plan"
                      : client?.new_payment_status !== "none"
                      ? "Select Plan"
                      : "Activate plan"
                  }
                  handleSubmit={() => handleActivePlanBtnClick(plan)}
                  // disabled={client?.new_payment_status !== "churned" && plan.planBeName === client?.new_subscription_plans}
                  leftButtonNoClick
                />
                {client?.new_payment_status === "none" && (
                  <Text style={{ fontSize: "12px", color: plan.name === "Growth" ? "white" : "#897575", alignSelf: "flexEnd", margin: "0 auto 20px auto" }}>
                    14 day free trial
                  </Text>
                )}
              </Flex>
            );
          })}
          {!isDesktop && (
            <Flex
              vertical={true}
              className={"plan"}
              style={{
                background:
                  enterprisePlan.name === "Growth" ? "linear-gradient(314deg, rgb(36 235 211 / 16%) -1.63%, rgb(0 173 238 / 16%) 71.91%), #FFF" : "#FFF",
                margin: "0 auto",
              }}
            >
              <Col className={"plan-header"} style={{ paddingRight: enterprisePlan.name === "Starter" ? 16 : 24 }}>
                <Flex gap={8} className={"plan-title-wrapper"}>
                  <SvgIcon type={enterprisePlan.iconType} width={20} height={20} />
                  <Typography.Title level={3} className={"plan-title"}>
                    {enterprisePlan.name}
                  </Typography.Title>
                </Flex>
                <Typography.Paragraph className={"plan-description"}>{enterprisePlan.description}</Typography.Paragraph>
              </Col>
              <Col className={"plan-main"}>
                <List
                  dataSource={enterprisePlan.advantages}
                  renderItem={(item) => (
                    <List.Item>
                      <SvgIcon type={"checked2"} width={24} height={24} className={"advantage-icon"} />
                      {item}
                    </List.Item>
                  )}
                />
                <ConfirmButtons rightButtonTitle={"Contact Sales"} handleSubmit={handleContactSalesBtnClick} style={{ width: 238, marginTop: 28 }} />
              </Col>
            </Flex>
          )}
          {isDesktop && (
            <Row className={"enterprise-wrapper"}>
              <Col className={"enterprise-left-col"}>
                <Flex gap={8} align={"center"}>
                  <SvgIcon type={"people"} width={20} height={20} />
                  <Typography.Title level={3} className={"enterprise-title"}>
                    Enterprise
                  </Typography.Title>
                </Flex>
                <Typography.Paragraph className={"enterprise-description"}>
                  Have a team that needs more robust lead generation and cross team scheduling functionality?
                </Typography.Paragraph>
              </Col>
              <Flex gap={24} className={"enterprise-right-col"}>
                <List
                  className={"enterprise-list"}
                  dataSource={enterprisePlan.advantages}
                  renderItem={(item) => (
                    <List.Item>
                      <SvgIcon type={"checked2"} width={16} height={16} className={"advantage-icon"} />
                      {item}
                    </List.Item>
                  )}
                />
                <ConfirmButtons rightButtonTitle={"Contact Sales"} handleSubmit={handleContactSalesBtnClick} style={{ width: 238 }} />
              </Flex>
            </Row>
          )}
          {!inDash ? (
            <div>
              <br />
              FAQ:
              <br />
              <br />
              <Collapse items={items} />
            </div>
          ) : (
            <Button onClick={() => setCancelSubscription(true)}>Cancel subscription</Button>
          )}
        </Flex>
      </Modal>
      {isLowerPriorityPlanModalOpened && <LowerPriorityPlanModal handleChangePlan={() => handleChangePlan(wantedPlan)} onClose={closeLowerPriorityPlanModal} />}
      {isSuccessfulActivationModalOpened && (
        <SuccessfulActivationModal
          isDiscount={isPriceDis ? "price" : midAfter && !now?.isAfter(midAfter) ? "extrCreds" : null}
          currentPlanName={requestedPlan}
          onClose={closeSuccessfulActivationModal}
          buildingPlan
          closeAll={handleOnCloseAll}
          location={location}
        />
      )}
      {isCancelSubscriptionModalOpened && <CancelSubscriptionModal cancelSubscription={cancelSubscriptionNow} onClose={closeCancelSubscriptionModal} />}
      {isPaymentIssueModalOpened && (
        <PaymentIssueModal contactSupport={handleContactSupport} checkPaymentDetails={handleCheckPaymentDetails} onClose={closePaymentIssueModal} />
      )}
      {isPaymentMethodModalOpened && (
        <PaymentMethodModal
          isTrial={client?.new_payment_status === "none"}
          checkout={requestedPlan}
          onClose={closePaymentMethodModal}
          payment
          discountType={isPriceDis ? "price" : midAfter && !now?.isAfter(midAfter) ? "extrCreds" : null}
          successfulPayment={handleSuccessfulPayment}
        />
      )}
    </React.Fragment>
  );
};
