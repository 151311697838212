/* eslint-disable react/jsx-fragments */
import { Flex, MenuProps, Row, Select, Tooltip, Typography, message } from "antd";
import { ColumnsType } from "antd/es/table";
import React, { FC, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { CustomSelect, StatusTag, Table, TableHeader } from "src/components/ui";
import { decode as atob, encode as btoa } from "base-64";
import { App } from "src/types";
import { campaignData } from "../campaignsData";
import { CampaignsEmptyTable } from "../CampaignsEmptyTable";
import { DotsMenuDropdown } from "../../../ui";
import "./index.less";
import { useDispatch, useSelector } from "react-redux";
import { outreachCampaignSelector, setPage, setRenameIds } from "src/store/outreach-campaigns";
import { SvgIcon } from "src/shared/icons";
import moment from "moment-timezone";
import { fullUserSelector } from "src/store/user";
import { AppDispatch } from "src/store";
import { delCamps, editCamps, getFilteredLeadsRst, resetCampIdDiscEmails, setCampPageNum } from "src/store/outreach-campaigns/asyncRequests";
import { RenameModal } from "./Modals/Rename";
import { DeleteModal } from "./Modals/DeleteConf";
import { ManageEmailDrawer } from "../../emails-data";
import { outreachEmailsSelector, resetOutcome as resOutcomeEm } from "src/store/outreach-emails";
import { addEmailBe } from "src/store/outreach-emails/asyncRequests";
import { CreatingCampaignModal } from "../CreateCampaign/ui/AIGenerated/ui/StepThree/ui/CreatingCampaignModal";

interface CampaignsTableProps {
  isTourStep?: boolean;
  showModal;
}

// TODOFF add relative statistics of how many out of prospects
const headerSelectOptions = [
  {
    label: "Overview",
    value: "default",
  },
  {
    type: "separator",
    label: "Quick Analytics:",
  },
  {
    label: "Emails",
    value: "email",
  },
  {
    label: "Phones",
    value: "phone",
  },
];

export const CampaignsTable: FC<CampaignsTableProps> = ({ isTourStep, showModal }) => {
  const [tourStepData, setTourStepData] = useState(campaignData);

  const [rename, setRename] = useState({});
  const [delAreYouSure, setDelAreYouSure] = useState({});
  const items = (camp) => [
    {
      key: "1",
      label: "Rename",
      onClick: () => setRename(camp),
    },
    {
      key: "2",
      label: "Delete",
      onClick: () => setDelAreYouSure(camp),
    },
  ];
  const memoizedTourStepData = useMemo(() => tourStepData, [tourStepData]);
  const navigate = useNavigate();

  // TODOC make it sort of fuzzy if no campaigns avail so they can see how itll look like
  const [tableCol, setTableCol] = useState("default");

  const { sdBarCollapsed } = useSelector(fullUserSelector);

  useEffect(() => {
    setTableCol("default");
  }, [isTourStep]);

  // useEffect(() => {
  //   setTableCol('default')
  // }, [isTourStep])

  const { outreachCampaignsList, totalCamps, outreachCampLoad, page, renameIds, loadingAddingCampaign } = useSelector(outreachCampaignSelector);
  console.log(outreachCampaignsList, "outreachCampaignsList");

  const closeRenameModel = () => {
    setRename({});
  };
  const closeDeleteModel = () => {
    setDelAreYouSure({});
  };

  // const [renameIds, setRenameIds] = useState([])
  const subRename = (id, name) => {
    message.info("Updating campaign name");
    dispatch(editCamps({ id, name, page }));

    const newRename = { id, name };

    const renCampsArr = renameIds.map((item) => {
      if (item.id === id) {
        return newRename;
      }
      return item;
    });

    const updatedRenameIds = renCampsArr.find((item) => item.id === id) ? renCampsArr : [...renCampsArr, newRename];
    console.log(updatedRenameIds, "updatedRenameIds");

    dispatch(setRenameIds(updatedRenameIds));
    dispatch(editCamps({ id, name, page }));
    closeRenameModel();
  };

  const subDelete = () => {
    // setDelAreYouSure({})
    console.log(delAreYouSure?.overview?.campId, "asdfasdf");
    dispatch(delCamps({ id: delAreYouSure?.overview?.campId || 0, del: true }));

    closeDeleteModel();
  };
  const [creatingCampaignModel, setCreatingCampaignModel] = useState(false);

  useEffect(() => {
    if (loadingAddingCampaign) {
      setCreatingCampaignModel(true);
    } else {
      setCreatingCampaignModel(false);
    }
  }, [loadingAddingCampaign]);

  useEffect(() => {
    setRenameIds([]);
  }, [outreachCampaignsList]);

  const dispatch = useDispatch<AppDispatch>();

  // const [page, setPage] = useState(1)
  const setCurrentPage = (pageNum) => {
    // dispatch(resetSelectedRows())
    // console.log(pageNum,'pagenum');
    dispatch(setPage(pageNum));
    dispatch(setCampPageNum({ pageNum }));
  };

  const [openDrawer, setOpenDrawer] = useState(false);

  const showDrawer = () => {
    setOpenDrawer(true);
  };
  const onCancel = () => {
    setOpenDrawer(false);
  };
  const location = useLocation();

  const { successfullUpdateMsg, failedUpdateMsg, infoUpdateMsg } = useSelector(outreachEmailsSelector);

  //  for when reconnecting emails this is necessary to show whats happening
  useEffect(() => {
    localStorage.removeItem("latestMeetzPg");

    const params = new Proxy(new URLSearchParams(window.location.search), {
      get: (searchParams, prop) => typeof prop === "string" && searchParams.get(prop),
    });
    const err = params?.error;
    const email = params?.em;
    const id = params?.id;
    const type = params?.type;
    const iframe = params?.iframe;
    if (err) {
      // push('/ai_email_campaign');
      // dispatch(emailErr({ email, type }));
      message.destroy();
      message.error(
        `Error integrating your ${
          type === "google" ? "Google" : "Outlook"
        } calendar. Make sure that you accepted the necessary permissions. Try to connect to it again soon.`
      );
    } else if (email && id) {
      dispatch(addEmailBe({ id, email }));
      message.destroy();
      message.info("Adding your email address now...");

      if (iframe === "true") {
        const jwt = localStorage.getItem("token");
        if (jwt) {
          window.location.href = `https://marketing.deal.ai/apps/app/meetz?appTokenName=token&appTokenValue=${jwt}`;
        } else {
          window.location.href = `https://marketing.deal.ai/apps/app/meetz`;
        }
      }
      window.history.pushState({}, "", "/campaigns");
      setOpenDrawer(true);
    }
  }, [location.search]);

  useEffect(() => {
    if (successfullUpdateMsg) {
      message.destroy();
      message.success(successfullUpdateMsg);
      dispatch(resOutcomeEm());
    }
  }, [successfullUpdateMsg]);
  useEffect(() => {
    if (failedUpdateMsg) {
      message.destroy();
      message.error(failedUpdateMsg);
      dispatch(resOutcomeEm());
    }
  }, [failedUpdateMsg]);
  useEffect(() => {
    if (infoUpdateMsg) {
      message.destroy();
      message.info(infoUpdateMsg);
      dispatch(resOutcomeEm());
    }
  }, [infoUpdateMsg]);

  // const testNmae='asfas asdsdfasfdsa asdf asdf asd fadssdffasfsdafsdaasdf fssss'
  const columns: ColumnsType<App.CampaignEntity> = [
    {
      title: "Campaign Name",
      className: "clickable-table-title",
      render: (record) => (
        <Typography.Paragraph
          style={{
            width: "100%",
            whiteSpace: "nowrap",
            overflow: "hidden",
            // textOverflow: 'ellipsis'
          }}
          onClick={() => {
            dispatch(
              getFilteredLeadsRst({
                uploadName: "All",
                recentlyUpdated: false,
                includeInCampaign: false,
                includeContactedAlready: false,
                page: 1,
                pageChange: false,
                selectedCampaignId: null,
              })
            );
            navigate(`/campaign/${btoa(`${record?.overview?.campId + 12000}`)}`);
          }}
        >
          {record?.overview?.campName?.trim()?.length > 40 ? (
            <Tooltip placement={"left"} title={record?.overview?.campName}>
              {record?.overview?.campName?.slice(0, 40)}...
            </Tooltip>
          ) : (
            record?.overview?.campName?.trim()
          )}
          {/* {record?.overview?.campName} */}
        </Typography.Paragraph>
      ),
      // key: "campaign",
      width: "300px",
    },
    // {
    //   title: "Contacts",
    //   dataIndex: "contacts",
    //   key: "contacts",
    //   align: "right",
    // },
    {
      title: "Total Contacts",
      dataIndex: "overview",
      render: (overview) => overview.prospectsInCamp,
      align: "left",
      // width: "100px",
    },
    {
      title: (
        <div style={{ display: "inline-flex", alignItems: "center" }}>
          Pros. Ready
          <Tooltip
            placement={"top"}
            title={
              <>
                Pros. Ready - all prospects that are ready to get the next step.
                <br />
                <br />
                Simply "Activate campaign" or go into the campaign and look at "Actions For Today" for more details.
                <br />
              </>
            }
          >
            <SvgIcon type={"tooltip"} style={{ marginLeft: 5, cursor: "pointer", verticalAlign: "middle" }} />
          </Tooltip>
        </div>
      ),
      // width: "100px",
      dataIndex: "overview",
      render: (overview) => overview.prosReady,
      key: "actionsForToday",
      align: "left",
    },
    // {
    //   title: "Prospects",
    //   dataIndex: "prospects",
    //   key: "prospects",
    //   align: "right",
    // },
    {
      title: (
        <div style={{ display: "inline-flex", alignItems: "center" }}>
          Pros. Finished{" "}
          <Tooltip
            placement={"top"}
            title={
              <>
                Pros. Finished - prospects that finished the whole flow of the campaign.
                <br />
                <br />
                Could be finished for a number of reasons:
                <br />
                <b>1.</b> Booked a meeting with you using the Meetz scheduling link.
                <b>2.</b> Any reply, positive or negative (Except for automated OOO emails).
                <b>3.</b> Unsubscribed.
                {/* <b>4.</b> booked a meeting with Meetz scheduling link. TODOF add also reason answered a call marked good */}
                <br />
              </>
            }
          >
            <SvgIcon type={"tooltip"} style={{ marginLeft: 5, cursor: "pointer", verticalAlign: "middle" }} />
          </Tooltip>
        </div>
      ),
      // width: "110px",
      dataIndex: "overview",
      render: (overview) => overview.prospectsFinished,
      // key: "createdAt",
    },
    {
      title: "Camp. Created",
      dataIndex: "overview",
      render: (overview) => (!overview?.unixCreated ? overview.createdAt || "" : moment.unix(overview.unixCreated).tz(moment.tz.guess()).format("MMM D, YYYY")),
      key: "createdAt",
    },
    {
      title: "Status",
      dataIndex: "status",
      // align:'center',
      // key: "status",
      render: (value, record) => (
        <Flex align={"center"} gap={8}>
          <Row className={"circle"} style={{ backgroundColor: record?.overview?.isActive ? "#0FCA7A" : "#FAAD14" }} />
          <Typography.Paragraph className={"table-text"}>{record?.overview?.isActive ? "Active" : "Inactive"}</Typography.Paragraph>
          {record?.overview?.isActive && !!record?.emailDisconnectedData?.length && !!record?.emailDisconnectedData[0]?.emailDisconnected?.length && (
            <>
              <Tooltip
                placement={"top"}
                title={
                  <>
                    <span style={{ fontWeight: 500, fontSize: "18px" }}>Warning:</span> Some prospects were assigned an email address that is now disconnected
                    from your account and now can't proceed in the step. <br />
                    (Individual prospects are assigned 1 of your email addresses to ensure they get emails only from 1 address throughout the flow)
                    <br />
                    <br />
                    Emails That Are Disconnected:
                    {record?.emailDisconnectedData?.map((em) => (
                      <>
                        {" "}
                        <br />
                        <span style={{ fontWeight: 600 }}>{em.emailDisconnected}</span> - {em.amount} prospect{em.amount > 1 && "s"} associated to this email
                      </>
                    ))}
                    <br />
                    <br />
                    <br />
                    <span style={{ cursor: "pointer", textDecoration: "underline", fontWeight: 500 }} onClick={showDrawer}>
                      Reconnect Email
                    </span>
                    - Recommended
                    <br />
                    <br />
                    <span
                      style={{ cursor: "pointer", textDecoration: "underline", color: "orangered", fontWeight: 500 }}
                      onClick={() => dispatch(resetCampIdDiscEmails({ campId: record?.overview?.campId }))}
                    >
                      Reassign email addresses
                    </span>{" "}
                    - Only if you can't reconnect emails
                  </>
                }
              >
                {/* TODOF add blog link so they can read why this happens not critical now */}
                <SvgIcon type={"warning"} style={{ marginLeft: 4, cursor: "pointer", width: "18px", height: "18px" }} />
              </Tooltip>
            </>
          )}
          {/* change this to if is active */}
        </Flex>
      ),
      // width: "170px",
    },
    {
      title: "Actions",
      key: "action",
      render: (_, record) => <DotsMenuDropdown menuItems={items(record)} />,
      width: "70px",
      align: "right",
    },
  ];
  const emailColumns: ColumnsType<App.CampaignEntity> = [
    {
      title: "Campaign Name",
      className: "clickable-table-title",
      render: (record) => (
        <Typography.Paragraph
          style={{
            width: "100%",
            whiteSpace: "nowrap",
            overflow: "hidden",
            // textOverflow: 'ellipsis'
          }}
          onClick={() => {
            dispatch(
              getFilteredLeadsRst({
                uploadName: "All",
                recentlyUpdated: false,
                includeInCampaign: false,
                includeContactedAlready: false,
                page: 1,
                pageChange: false,
                selectedCampaignId: null,
              })
            );
            navigate(`/campaign/${btoa(`${record?.overview?.campId + 12000}`)}`);
          }}
        >
          {record?.overview?.campName?.trim()?.length > 30 ? (
            <Tooltip placement={"left"} title={record?.overview?.campName}>
              {record?.overview?.campName?.slice(0, 30)}...
            </Tooltip>
          ) : (
            record?.overview?.campName?.trim()
          )}
          {/* {record?.overview?.campName} */}
        </Typography.Paragraph>
      ),
      // key: "campaign",
      width: "250px",
    },

    {
      title: "Em. Sent",
      dataIndex: "emailAnalytics",
      render: (emailAnalytics) => (
        <StatusTag color={"purple"} value={emailAnalytics.sentTotal} style={{ padding: "5px 20px", borderWidth: "1px", borderColor: "lightgrey" }} />
      ),
      align: "center",
    },
    {
      title: (
        <div style={{ display: "inline-flex", alignItems: "center" }}>
          {" "}
          Em. Opened
          <Tooltip
            placement={"top"}
            title={
              <>
                We remove your own opens from these statistics for accuracy. Feel free to open the sent email, only your prospects opens are counted.
                <br />
                <br />
                Occasionally prospects firewalls could "open" the email which affects the open count slightly, use the "em. Likely Forwarded" for the most
                accurate measurement.
                <br />
              </>
            }
          >
            <SvgIcon type={"tooltip"} style={{ marginLeft: 10, cursor: "pointer", verticalAlign: "middle" }} />
          </Tooltip>
        </div>
      ),
      dataIndex: "emailAnalytics",
      render: (emailAnalytics) => (
        <StatusTag color={"cyan"} value={emailAnalytics.opened} style={{ padding: "5px 20px", borderWidth: "1px", borderColor: "lightgrey" }} />
      ),
      align: "center",
    },
    {
      title: (
        <div style={{ display: "inline-flex", alignItems: "center" }}>
          Em. Likely Forwarded{" "}
          <Tooltip
            placement={"top"}
            title={
              <>
                This indicates if a prospect has likely forwarded your email internally, which suggests interest.
                <br />
                <br />
                What this means for you:
                <br />
                <br />
                <b>1.</b> Prospects find your offer interesting or relevant to their team (Most likely)
                <br />
                <b>2.</b> A high forward rate (e.g., 10 out of 30) means the target company is correct, but the position might need adjustment.
                <br />
                <b>3.</b> If at least 10% of emails are forwarded by campaign end, your targeting is good. If responses are low just adjust your CTA.
              </>
            }
          >
            <SvgIcon type={"tooltip"} style={{ marginLeft: 10, cursor: "pointer", verticalAlign: "middle" }} />
          </Tooltip>
        </div>
      ),
      dataIndex: "emailAnalytics",
      render: (emailAnalytics) => (
        <StatusTag color={"processing"} value={emailAnalytics.forwarded} style={{ padding: "5px 20px", borderWidth: "1px", borderColor: "lightgrey" }} />
      ),
      width: "160px",
      align: "center",
    },
    {
      title: "Em. Replied",
      dataIndex: "emailAnalytics",
      render: (emailAnalytics) => (
        <StatusTag color={"success"} value={emailAnalytics.replied} style={{ padding: "5px 20px", borderWidth: "1px", borderColor: "lightgrey" }} />
      ),
      align: "center",
    },

    {
      title: "Actions",
      key: "action",
      render: (_, record) => <DotsMenuDropdown menuItems={items(record)} />,
    },
  ];
  const phoneColumns: ColumnsType<App.CampaignEntity> = [
    {
      title: "Campaign Name",
      className: "clickable-table-title",
      render: (record) => (
        <Typography.Paragraph
          style={{
            width: "100%",
            whiteSpace: "nowrap",
            overflow: "hidden",
            // textOverflow: 'ellipsis'
          }}
          onClick={() => {
            dispatch(
              getFilteredLeadsRst({
                uploadName: "All",
                recentlyUpdated: false,
                includeInCampaign: false,
                includeContactedAlready: false,
                page: 1,
                pageChange: false,
                selectedCampaignId: null,
              })
            );
            navigate(`/campaign/${btoa(`${record?.overview?.campId + 12000}`)}`);
          }}
        >
          {record?.overview?.campName?.trim()?.length > 30 ? (
            <Tooltip placement={"left"} title={record?.overview?.campName}>
              {record?.overview?.campName?.slice(0, 30)}...
            </Tooltip>
          ) : (
            record?.overview?.campName?.trim()
          )}
          {/* {record?.overview?.campName} */}
        </Typography.Paragraph>
      ),
      // key: "campaign",
      width: "250px",
    },

    {
      title: "Dials",
      dataIndex: "phoneAnalytics",
      render: (phoneAnalytics) => (
        <StatusTag color={"cyan"} value={phoneAnalytics.dials} style={{ padding: "5px 20px", borderWidth: "1px", borderColor: "lightgrey" }} />
      ),
      align: "center",
    },
    {
      title: "Incoming Call",
      dataIndex: "phoneAnalytics",
      render: (phoneAnalytics) => (
        <StatusTag color={"geekblue"} value={phoneAnalytics.dialIncoming} style={{ padding: "5px 20px", borderWidth: "1px", borderColor: "lightgrey" }} />
      ),
      align: "center",
    },
    {
      title: "Status - Bad Call",
      dataIndex: "phoneAnalytics",
      render: (phoneAnalytics) => (
        <StatusTag color={"purple"} value={phoneAnalytics.markedBadConv} style={{ padding: "5px 20px", borderWidth: "1px", borderColor: "lightgrey" }} />
      ),
      align: "center",
    },
    {
      title: "Status - Good Call",
      dataIndex: "phoneAnalytics",
      render: (phoneAnalytics) => (
        <StatusTag color={"success"} value={phoneAnalytics.markedGoodConv} style={{ padding: "5px 20px", borderWidth: "1px", borderColor: "lightgrey" }} />
      ),
      align: "center",
    },
    // { TODOF for later add
    //   title: "SMS Sent",
    //   dataIndex: 'phoneAnalytics',
    //   render: (phoneAnalytics) =>  <StatusTag color={"success"} value={phoneAnalytics.replied}  style={{padding:'5px 20px',borderWidth:'1px',borderColor:'lightgrey'}} />,
    //   align: "center",
    // },
    // {
    //   title: "SMS In",
    //   dataIndex: 'phoneAnalytics',
    //   render: (phoneAnalytics) =>  <StatusTag color={"success"} value={phoneAnalytics.replied}  style={{padding:'5px 20px',borderWidth:'1px',borderColor:'lightgrey'}} />,
    //   align: "center",
    // },

    {
      title: "Actions",
      key: "action",
      render: (_, record) => <DotsMenuDropdown menuItems={items(record)} />,
    },
  ];

  //  const updCampList = outreachCampaignsList

  const updCampList = outreachCampaignsList?.map((camp) => {
    const renameItem = renameIds.find((item) => item.id === camp.overview.campId);
    if (renameItem) {
      return {
        ...camp,
        overview: {
          ...camp.overview,
          campName: renameItem.name, // Update the name with the new one
        },
      };
    }
    return camp; // If no renameItem found, return the campaign as is
  });

  return (
    <div className={"campaign-wrapper"}>
      {updCampList?.length === 0 && !isTourStep ? (
        <CampaignsEmptyTable showModal={showModal} />
      ) : (
        <>
          <TableHeader
            // searchPlaceholder={"Search Campaigns..."} TODOF add later
            rightHeaderContent={
              <>
                <div />
                <Select
                  value={tableCol}
                  onChange={(e) => {
                    setTableCol(e);
                  }}
                  style={{ width: "fit-content", minWidth: 192 }}
                >
                  {headerSelectOptions.map((option, index) => {
                    if (option.type === "separator") {
                      return (
                        <Option key={`separator-${index}`} style={{ padding: 0 }} disabled>
                          <div style={{ borderTop: "1px solid #e8e8e8", margin: "4px 0" }} />
                          <div style={{ color: "#8c8c8c", fontSize: "12px", padding: "8px 0", textAlign: "center" }}>{option.label}</div>
                        </Option>
                      );
                    }
                    return (
                      <Option key={option.value} value={option.value}>
                        {option.label}
                      </Option>
                    );
                  })}
                </Select>
              </>
            }
          />
          {isTourStep ? (
            <Table columns={columns} dataSource={memoizedTourStepData} divWidth={"calc(100vw - 400px)"} />
          ) : (
            <>
              {tableCol === "default" ? (
                <Table
                  columns={columns}
                  dataSource={updCampList}
                  divWidth={sdBarCollapsed ? "calc(100vw - 230px)" : "calc(100vw - 400px)"}
                  pageSize={10}
                  totalPages={totalCamps ? Math.ceil(totalCamps / 10) : 1}
                  currentPage={page}
                  setCurrentPage={setCurrentPage}
                  totalAmnt={totalCamps}
                  scroll={{ x: "100%" }}
                  loading={outreachCampLoad}
                />
              ) : tableCol === "email" ? (
                <Table
                  loading={outreachCampLoad}
                  columns={emailColumns}
                  dataSource={updCampList}
                  divWidth={sdBarCollapsed ? "calc(100vw - 230px)" : "calc(100vw - 400px)"}
                  pageSize={10}
                  totalPages={totalCamps ? Math.ceil(totalCamps / 10) : 1}
                  currentPage={page}
                  setCurrentPage={setCurrentPage}
                  totalAmnt={totalCamps}
                  scroll={{ x: "100%" }}
                />
              ) : (
                <Table
                  loading={outreachCampLoad}
                  columns={phoneColumns}
                  dataSource={updCampList}
                  divWidth={sdBarCollapsed ? "calc(100vw - 230px)" : "calc(100vw - 400px)"}
                  pageSize={10}
                  totalPages={totalCamps ? Math.ceil(totalCamps / 10) : 1}
                  currentPage={page}
                  setCurrentPage={setCurrentPage}
                  totalAmnt={totalCamps}
                  scroll={{ x: "100%" }}
                />
              )}

              <RenameModal
                open={!!rename?.overview}
                defaultVal={rename?.overview?.campName || ""}
                idEditing={rename?.overview?.campId}
                onSubmit={subRename}
                closeModal={closeRenameModel}
              />
              <DeleteModal open={!!delAreYouSure?.overview} onSubmit={subDelete} closeModal={closeDeleteModel} />
            </>
          )}
        </>
      )}
      <ManageEmailDrawer open={openDrawer} onCancel={onCancel} /> <CreatingCampaignModal open={creatingCampaignModel} />
    </div>
  );
};
